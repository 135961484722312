<mat-toolbar>{{ page?.name ?? 'Add ' + pageTypeDisplay }}</mat-toolbar>
<div sol-scroll-shadow-container class="amm-page-form-wrapper" [ngStyle]="getContainerStyles(formState)">
	<mat-card class="amm-card">
		<app-page-form #form [page]="page" [pageType]="DASHBOARD" (formChange)="onFormChange($event)" class="amm-page-form" />
	</mat-card>
</div>
<mat-toolbar class="amm-bottom-bar">
	<button mat-button (click)="cancel()">Cancel</button>
	<button class="submit-button" mat-button color="primary" [disabled]="!formState.valid" (click)="submit()">
		Save
	</button>
</mat-toolbar>