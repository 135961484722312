import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { GradientColorOption, GradientTextureOption, IFormChange, IGradientConfig, IModule, IPage, PageType } from '@soleran/contracts';
import { GlobalDrawerService } from '../../../global-drawer/global-drawer.service';
import { IModuleFormValue } from '@soleran/ngx-module';
import { toRawGradientStyles } from '@soleran/ngx-layout-utility';
import { DEFAULT_GRADIENT_CONFIG } from '../../constants/default-gradient-config';

@Component({
  selector: 'app-page-modal',
  templateUrl: './page-modal.component.html',
  styleUrl: './page-modal.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
	  class: 'app-page-modal'
  }
})
export class PageModalComponent implements OnInit {
	@Input() page?: IPage;
	@Input() pageType: PageType = PageType.Generic;
	
	formState!: IFormChange<IPage>;
	DASHBOARD = PageType.Dashboard;
	
	pageDisplayLookup = {
		[PageType.Generic]: 'Generic',
		[PageType.Dashboard]: 'Dashboard',
		[PageType.Object]: 'Object'
	  }
	  
  get pageTypeDisplay() {
    return this.pageDisplayLookup[this.pageType];
  }

	constructor(private _globalDrawer: GlobalDrawerService) { }

	ngOnInit(): void {
		this.formState  = {
			valid: false,
			value: this.page
		};
	}

	onFormChange(change: IFormChange<IPage>) {
		this.formState = change;
	}
	getContainerStyles(formState: any) {
		const gradient = formState.value?.gradient ?? DEFAULT_GRADIENT_CONFIG;
		const styles = toRawGradientStyles(gradient)
		return styles;
	}
	

	cancel() {
		this._globalDrawer.close();
	}
	submit() {
		this._globalDrawer.close(this.formState.value);
	}
}