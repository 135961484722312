<form [formGroup]="form" #container class="form">
    <div class="row">
        <sol-icon-input formControlName="icon"></sol-icon-input>
        <mat-form-field class="form-field" appearance="outline">
            <mat-label>{{pageTypeDisplay}} Name</mat-label>
            <input matInput formControlName="name" #nameField />
            <mat-error *ngIf="this.form.get('name')?.errors?.['required']">Name is required.</mat-error>
        </mat-form-field>
    </div>
    <mat-form-field appearance="outline">
        <mat-label>{{pageTypeDisplay}} Description</mat-label>
        <textarea matInput formControlName="description"></textarea>
    </mat-form-field>
    <mat-divider class="divider"></mat-divider>
    <div class="gradient">
        <label class="label">Background</label>
        <sol-gradient-picker formControlName="gradient"></sol-gradient-picker>
    </div>
</form>