import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GradientColorOption, GradientTextureOption, IFormChange, IPage, PageType } from '@soleran/contracts';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DEFAULT_GRADIENT_CONFIG } from '../../constants/default-gradient-config';

@Component({
  selector: 'app-page-form',
  templateUrl: './page-form.component.html',
  styleUrl: './page-form.component.scss'
})
export class PageFormComponent implements OnInit, AfterViewInit {
  @ViewChild('nameField', { static: true }) nameField!: ElementRef;

  @Input() page?: IPage;
  @Input() pageType: PageType = PageType.Generic;
  @Output() formChange = new EventEmitter<IFormChange<IPage>>();

  form!: FormGroup;
  pageDisplayLookup = {
    [PageType.Generic]: 'Generic',
    [PageType.Dashboard]: 'Dashboard',
    [PageType.Object]: 'Object'
  }

  constructor(
    private _formBuilder: FormBuilder,
    private _destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    this._initForm();
  }
  get pageTypeDisplay() {
    return this.pageDisplayLookup[this.pageType];
  }

  private _initForm() {
    const pageData: IPage = this.page ?? {
      id: undefined,
      name: '',
      type: this.pageType,
      active: true,
      appId: '',
      description: '',
      icon: '',
      gradient: DEFAULT_GRADIENT_CONFIG
    };

    this.form = this._formBuilder.group({
      id: [pageData.id],
      name: [pageData.name, Validators.required],
      type: [pageData.type],
      active: [pageData.active],
      appId: [pageData.appId],
      description: [pageData.description],
      icon: [pageData.icon],
      gradient: [pageData.gradient]
    });

    this._trackFormChanges();
  }

  private _trackFormChanges() {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(updatedValues => {
        this.formChange.emit({
          valid: this.form.valid,
          value: { ...updatedValues }
        });
      });
  }

  ngAfterViewInit(): void {
    this._initFocus();
  }

  private _initFocus() {
    this.nameField.nativeElement.focus();
  }
}
